<template>
  <article class="withdrawal-wrap">
    <p class="txt-title">
      선생님, 혹시 불편하신 점이 있으셨나요?
    </p>

    <router-link
      :to="{ path: 'askHistory' }"
      class="btn-go"
    >
      문의 남기러 가기
    </router-link>

    <p class="txt-title">
      계정 연결 해제 전, 꼭 확인하세요.
    </p>

    <div class="withdrawal-list">
      <ol>
        <li>계정 연결 해제 시, 가지고 계신 포인트는 모두 삭제되며 복구되지 않습니다.<br>필요한 경우, 연결 해제 전에 포인트를 모두 사용해 주세요.</li>
        <li>계정 연결 해제 시, 닥터인포에 등록된 선생님의 개인 정보, 사전 예약 정보, 활동 내역은 모두 삭제되며 복구되지 않습니다.<br>단, 관계 법령에 따라 회사가 보관해야 하는 정보는 일정 기간 보관 처리합니다.</li>
        <li>계정 연결 해제 후, 닥터인포에 작성한 댓글은 수정하거나 삭제할 수 없습니다. <br>
          필요한 경우, 계정 연결 해제 전 댓글을 수정하거나 삭제해 주세요.</li>
        <li>계정 연결 해제 시, 1개월 이후 다시 계정 연결이 가능합니다.</li>
      </ol>
    </div>

    <div class="checkbox-wrap">
      <input
        id="withdrawalAgree"
        v-model="agree"
        type="checkbox"
      >
      <label for="withdrawalAgree">위 안내사항을 확인하였으며, 계정 연결 해제 시 개인 정보와 잔여 포인트는 모두 삭제되어 복구되지 않음을 인지하였고 이에 동의합니다.</label>
    </div>

    <p class="txt-title">
      어떤 점이 불편하셨나요?
    </p>
    <div class="form-box">
      <div class="radio-wrap">
        <input
          id="serviceType01"
          v-model="withdrawType"
          type="radio"
          name="serviceType"
          value="1"
        >
        <label for="serviceType01">서비스 이용이 불편해서</label>
      </div>
      <div class="radio-wrap">
        <input
          id="serviceType02"
          v-model="withdrawType"
          type="radio"
          name="serviceType"
          value="2"
        >
        <label for="serviceType02">사용 빈도가 낮아서</label>
      </div>
      <div class="radio-wrap">
        <input
          id="serviceType03"
          v-model="withdrawType"
          type="radio"
          name="serviceType"
          value="3"
        >
        <label for="serviceType03">기록(정보) 삭제 목적</label>
      </div>
      <div class="radio-wrap">
        <input
          id="serviceType04"
          v-model="withdrawType"
          type="radio"
          name="serviceType"
          value="4"
        >
        <label for="serviceType04">콘텐츠 불만</label>
      </div>
    </div>
    <div class="form-box">
      <div class="radio-wrap">
        <input
          id="serviceTypeElse"
          v-model="withdrawType"
          type="radio"
          name="serviceType"
          value="5"
        >
        <label for="serviceTypeElse">기타(직접 입력)</label>
      </div>
      <div class="textarea-wrap">
        <textarea
          v-model.trim="etcText"
          placeholder="회원 탈퇴시, 가지고 계신 포인트는 모두 삭제되며 복구되지 않습니다.필요한 경우, 탈퇴 전에 포인트를 모두 사용해 주세요."
          :disabled="withdrawType !== '5'"
        />
      </div>
    </div>

    <p class="txt-title">
      비밀번호를 입력해 주세요.
    </p>
    <div class="text-wrap">
      <input
        v-model="password"
        v-number
        type="password"
        maxlength="6"
      >
    </div>

    <div class="btn-2-wrap">
      <button
        class="btn-cancel"
        @click="$router.go(-1)"
      >
        취소
      </button>
      <button
        class="btn-next"
        @click="withdrawal"
      >
        연결 해제하기
      </button>
    </div>
  </article>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import eventBus from '@/libs/event/event-bus'

export default {
  data() {
    return {
      agree: false,
      withdrawType: '1',
      etcText: null,
      password: null,
    }
  },
  computed: {
    ...mapGetters({
      userPoint: 'account/getPoint',
    }),
  },
  watch: {
    withdrawType(val) {
      if (val !== '5') this.etcText = null
    },
  },
  methods: {
    withdrawal() {
      if (this.agree === false) {
        this.$parent.showAlertDialog('안내사항에 동의해 주세요.')
      } else if (this.withdrawType == null) {
        this.$parent.showAlertDialog('불편하셨던 사유 1가지에 체크해 주세요.')
      } else if (this.withdrawType === '5' && (this.etcText == null || this.etcText.length === 0)) {
        this.$parent.showAlertDialog('불편하셨던 사유를 입력해 주세요.')
      } else if (this.password == null || this.password.length === 0) {
        this.$parent.showAlertDialog('비밀번호를 입력해 주세요.')
      } else {
        const point = String(this.userPoint).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        const subMessage = `
            <p class="txt-red">잔여 포인트는 ${point}P 입니다.</p>
            <p class="txt-else">계정 연결 해제 시 모든 개인 정보가 삭제되며, 복구되지 않습니다.<br/>그래도 연결 해제를 원하신다면 확인을 눌러 주세요.</p>
        `

        this.$parent.showConfirmDialog('정말 연결을 해제하시겠습니까?', confirm => {
          if (confirm) {
            axios.delete(`/fu/user`, {
              data: {
                password: this.password,
                withdrawType: this.withdrawType,
                etcText: this.etcText,
              },
            })
              .then(() => {
                this.$store.commit('util/setJoin', false)

                eventBus.$emit('logout')
              })
              .catch(error => {
                this.$parent.showAlertDialog(error.response.data)
              })
          }
        }, subMessage)
      }
    },
  },
}
</script>
