var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "withdrawal-wrap" },
    [
      _c("p", { staticClass: "txt-title" }, [
        _vm._v(" 선생님, 혹시 불편하신 점이 있으셨나요? "),
      ]),
      _c(
        "router-link",
        { staticClass: "btn-go", attrs: { to: { path: "askHistory" } } },
        [_vm._v(" 문의 남기러 가기 ")]
      ),
      _c("p", { staticClass: "txt-title" }, [
        _vm._v(" 계정 연결 해제 전, 꼭 확인하세요. "),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "checkbox-wrap" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.agree,
              expression: "agree",
            },
          ],
          attrs: { id: "withdrawalAgree", type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.agree)
              ? _vm._i(_vm.agree, null) > -1
              : _vm.agree,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.agree,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.agree = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.agree = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.agree = $$c
              }
            },
          },
        }),
        _c("label", { attrs: { for: "withdrawalAgree" } }, [
          _vm._v(
            "위 안내사항을 확인하였으며, 계정 연결 해제 시 개인 정보와 잔여 포인트는 모두 삭제되어 복구되지 않음을 인지하였고 이에 동의합니다."
          ),
        ]),
      ]),
      _c("p", { staticClass: "txt-title" }, [
        _vm._v(" 어떤 점이 불편하셨나요? "),
      ]),
      _c("div", { staticClass: "form-box" }, [
        _c("div", { staticClass: "radio-wrap" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.withdrawType,
                expression: "withdrawType",
              },
            ],
            attrs: {
              id: "serviceType01",
              type: "radio",
              name: "serviceType",
              value: "1",
            },
            domProps: { checked: _vm._q(_vm.withdrawType, "1") },
            on: {
              change: function ($event) {
                _vm.withdrawType = "1"
              },
            },
          }),
          _c("label", { attrs: { for: "serviceType01" } }, [
            _vm._v("서비스 이용이 불편해서"),
          ]),
        ]),
        _c("div", { staticClass: "radio-wrap" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.withdrawType,
                expression: "withdrawType",
              },
            ],
            attrs: {
              id: "serviceType02",
              type: "radio",
              name: "serviceType",
              value: "2",
            },
            domProps: { checked: _vm._q(_vm.withdrawType, "2") },
            on: {
              change: function ($event) {
                _vm.withdrawType = "2"
              },
            },
          }),
          _c("label", { attrs: { for: "serviceType02" } }, [
            _vm._v("사용 빈도가 낮아서"),
          ]),
        ]),
        _c("div", { staticClass: "radio-wrap" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.withdrawType,
                expression: "withdrawType",
              },
            ],
            attrs: {
              id: "serviceType03",
              type: "radio",
              name: "serviceType",
              value: "3",
            },
            domProps: { checked: _vm._q(_vm.withdrawType, "3") },
            on: {
              change: function ($event) {
                _vm.withdrawType = "3"
              },
            },
          }),
          _c("label", { attrs: { for: "serviceType03" } }, [
            _vm._v("기록(정보) 삭제 목적"),
          ]),
        ]),
        _c("div", { staticClass: "radio-wrap" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.withdrawType,
                expression: "withdrawType",
              },
            ],
            attrs: {
              id: "serviceType04",
              type: "radio",
              name: "serviceType",
              value: "4",
            },
            domProps: { checked: _vm._q(_vm.withdrawType, "4") },
            on: {
              change: function ($event) {
                _vm.withdrawType = "4"
              },
            },
          }),
          _c("label", { attrs: { for: "serviceType04" } }, [
            _vm._v("콘텐츠 불만"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "form-box" }, [
        _c("div", { staticClass: "radio-wrap" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.withdrawType,
                expression: "withdrawType",
              },
            ],
            attrs: {
              id: "serviceTypeElse",
              type: "radio",
              name: "serviceType",
              value: "5",
            },
            domProps: { checked: _vm._q(_vm.withdrawType, "5") },
            on: {
              change: function ($event) {
                _vm.withdrawType = "5"
              },
            },
          }),
          _c("label", { attrs: { for: "serviceTypeElse" } }, [
            _vm._v("기타(직접 입력)"),
          ]),
        ]),
        _c("div", { staticClass: "textarea-wrap" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.etcText,
                expression: "etcText",
                modifiers: { trim: true },
              },
            ],
            attrs: {
              placeholder:
                "회원 탈퇴시, 가지고 계신 포인트는 모두 삭제되며 복구되지 않습니다.필요한 경우, 탈퇴 전에 포인트를 모두 사용해 주세요.",
              disabled: _vm.withdrawType !== "5",
            },
            domProps: { value: _vm.etcText },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.etcText = $event.target.value.trim()
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
      _c("p", { staticClass: "txt-title" }, [
        _vm._v(" 비밀번호를 입력해 주세요. "),
      ]),
      _c("div", { staticClass: "text-wrap" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
            { name: "number", rawName: "v-number" },
          ],
          attrs: { type: "password", maxlength: "6" },
          domProps: { value: _vm.password },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "btn-2-wrap" }, [
        _c(
          "button",
          {
            staticClass: "btn-cancel",
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v(" 취소 ")]
        ),
        _c(
          "button",
          { staticClass: "btn-next", on: { click: _vm.withdrawal } },
          [_vm._v(" 연결 해제하기 ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "withdrawal-list" }, [
      _c("ol", [
        _c("li", [
          _vm._v(
            "계정 연결 해제 시, 가지고 계신 포인트는 모두 삭제되며 복구되지 않습니다."
          ),
          _c("br"),
          _vm._v("필요한 경우, 연결 해제 전에 포인트를 모두 사용해 주세요."),
        ]),
        _c("li", [
          _vm._v(
            "계정 연결 해제 시, 닥터인포에 등록된 선생님의 개인 정보, 사전 예약 정보, 활동 내역은 모두 삭제되며 복구되지 않습니다."
          ),
          _c("br"),
          _vm._v(
            "단, 관계 법령에 따라 회사가 보관해야 하는 정보는 일정 기간 보관 처리합니다."
          ),
        ]),
        _c("li", [
          _vm._v(
            "계정 연결 해제 후, 닥터인포에 작성한 댓글은 수정하거나 삭제할 수 없습니다. "
          ),
          _c("br"),
          _vm._v(
            " 필요한 경우, 계정 연결 해제 전 댓글을 수정하거나 삭제해 주세요."
          ),
        ]),
        _c("li", [
          _vm._v("계정 연결 해제 시, 1개월 이후 다시 계정 연결이 가능합니다."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }